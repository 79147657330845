import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { CircularProgress } from '@material-ui/core';
import Page from '../../components/Layout/Page';
import './Return.css';
import {
  resetReturnProducts,
  resetReturnCode,
  setReturnBillNumber,
  setReturnSmartPOSTransactionId,
  setReturnCode,
  setReturnProducts,
} from '../../actions/customerActions';
import axios from '../../utils/axios';
import { STATUS_BILLING_PROCESSING } from '../../utils/const';

function ReturnEntry() {
  const dispatch = useDispatch();

  const customer = useSelector((state) => state.customer);
  const { return_type: returnType } = customer || {};
  const { Store: store } = useSelector((state) => state.account.user || {});

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(resetReturnProducts());
    dispatch(resetReturnCode());
  }, [dispatch]);

  const setReturnData = async (order = {}) => {
    const returnProducts = order.OrderProducts;

    const orderId = order.id;
    dispatch(setReturnCode(orderId));
    const payments = await axios.get(`/api/payments/order/${orderId}`);
    if (payments.data?.data?.length) {
      // Check if the payment were made in smart pos
      const smartPOSTransactionIds = [];
      payments.data.data.forEach((p) => {
        if (p.smart_pos_transaction_id) {
          smartPOSTransactionIds.push(p.smart_pos_transaction_id);
        }
      });
      if (smartPOSTransactionIds.length) {
        dispatch(setReturnSmartPOSTransactionId(smartPOSTransactionIds));
      }
    }

    const returnFinal = [];
    if (order.Returns.length > 0) {
      const amounts = {};
      order.Returns.forEach((returnOrder) => {
        // revisamos cada return dentro
        returnOrder.ReturnProducts.forEach((product) => {
          // Vemos cada producto
          amounts[product.product_id] = amounts[product.product_id]
            ? product.amount + amounts[product.product_id] : product.amount;
        });
      });
      returnProducts.forEach((prod) => {
        const product = amounts[prod.product_id]
          ? { ...prod, amount: prod.amount - amounts[prod.product_id] }
          : { ...prod, amount: prod.amount };
        returnFinal.push(product);
      });
      dispatch(setReturnProducts(returnFinal));
    } else {
      dispatch(setReturnProducts(returnProducts));
    }
    setLoading(false);
    window.location.href = '/carro-devolucion';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(setReturnBillNumber(code));

    setLoading(true);
    try {
      const response = await axios.get(`/api/order-by-bill/${code}`, {
        params: {
          doc_type: returnType,
          store_id: store?.id || '',
        },
      });

      if (response.data.code === 200) {
        const { order = {} } = response.data.data;
        if (!order.id) {
          setLoading(false);
          setError('No se encontraron órdenes para el número ingresado.');
        } else {
          await setReturnData(order);
        }
      } else {
        setLoading(false);
        setError('El código ingresado no existe.');
      }
    } catch (err) {
      setLoading(false);
      const errorMessage = err.response.data.errorMessage || err.message;
      setError(errorMessage);
    }
  };

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  if (customer.order_id
    && (customer.order_status === STATUS_BILLING_PROCESSING)) {
    return (<Redirect to="pago-final" />);
  }

  return (
    <Page
      title="Club Líquidos | Devoluciones"
      progressStep="none"
      backRef="/tipo-devolucion"
    >
      <div className="return-container">
        <h1>
          {returnType ? `Ingresar Número de ${returnType}` : 'Ingresar Número de Documento'}
        </h1>
        <form className="code-form-field" onSubmit={handleSubmit}>
          <input
            id="code"
            type="text"
            name="code"
            value={code}
            onChange={(e) => handleChange(e)}
          />
          <button type="submit" className="yellow-button">
            {loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : 'Continuar'}
          </button>
        </form>
        <p className="error-message">{error}</p>
      </div>
    </Page>
  );
}

export default ReturnEntry;
